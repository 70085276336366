<template>
  <div class="container">
    <div class="live-detail">
      <div class="header">
        <img class="main-banner" :src="info.cover" alt="">
        <p class="title">{{info.title}}</p>
      </div>

      <div class="detail-interact">
        <p class="span">{{info.title_sub}}</p>
        <p class="span">{{info.meeting_time}}</p>
      </div>

      <van-tabs class="has-bg-tabs" v-model="activeName">
        <van-tab title="会议介绍" name="1">
          <div class="content-intro">
            <div class="rich-text" v-html="htmlSnipOne"></div>
          </div> 
        </van-tab>
        <van-tab title="大会议程" name="2">
          <div class="content-intro">
            <div class="rich-text" v-html="htmlSnipTwo"></div>
          </div> 
        </van-tab>
        <van-tab title="大会嘉宾" name="3">
          <div class="content-intro">
            <div class="rich-text" v-html="htmlSnipThree"></div>
          </div> 
        </van-tab>
      </van-tabs>

    </div>
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";
export default {
  data(){
    return {
      info: {},
      activeName: '1',
      htmlSnipOne: '',
      htmlSnipTwo: '',
      htmlSnipThree: ''
    }
  },
  created() {
    const id = this.$untils.getUrlKey('id');
    this.getInfo(id)
  },
  mounted() {
    
  },
  methods:{
    //获取详情
    getInfo(id) {
      let that = this
      this.$api.MEETTING_DETAIL_GET({
        'id': id
      }).then(res => {
        that.info = res
        that.htmlSnipOne = that.info.content
        that.htmlSnipTwo = that.info.agenda
        that.htmlSnipThree = that.info.guest
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .live-detail .main-banner{
    display:block;
    width:100%;
    margin-top:10px;
  }

  .live-detail .title{
    display:block;
    font-size:16px;
    color:#000;
    padding-top:16px;
    padding-bottom: 8px;
  }

  .catalogue-item{
    background:#F4F4F4;
    border-radius:8px;
    padding:12px 13px;
    margin-top:16px;
    font-size:15px;
    color:#000;
  }

  .detail-interact{
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:30px;
  }

  .detail-interact .span{
    color:#707070;
    font-size:12px;
  }
</style>